import React from "react";
import { Link } from "react-router-dom";
import Menu from "./Menu";
import UserContext from "../../Helpers/UserContext";
import { useQuery } from '@apollo/client';
import { GET_ALL_COURIER_TRANSACTIONS } from "../../GraphQL/Queries";
import { APIResponse } from '../../Helpers/General';
import Session from '../../Helpers/Session';
import FormatDate from '../../Helpers/FormatDate';
import NumberToMoney from '../../Helpers/numberToMoney';
import { DateTime } from 'luxon';

const Wallet: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [hideBalance, setHideBalance] = React.useState<boolean>(false);
    const [balance, setBalance] = React.useState<number>(user?.balance || 0.00);
    const [transactions, setTransactions] = React.useState([]);
    const name = user?.fullname;

    const { refetch } = useQuery(GET_ALL_COURIER_TRANSACTIONS, {
        fetchPolicy: 'network-only',
        variables: {
            offset: 0,
        },
        onCompleted: (data) => {
            setTransactions(data.courierTransactions);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const formatTransaction = (transaction: Record<string, any>) => {
        if (transaction.remarks === 'WITHDRAWAL') {
            return (
                <div className="w3-animate-zoom shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-down-left fs-3 text-negative"></i>
                            </div>
                            <div className="ms-3">
                                <p className="mb-0 fw-bold">{name}</p>
                                <p className="mb-0 text-muted">{FormatDate(transaction.created_at)}</p>
                            </div>
                        </div>
                        <div>
                            <p className="mb-0 text-negative fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                        </div>
                    </div>
                </div>
            )
        } else if (transaction.order) {
            const order = transaction.order;
            return (
                <div className="w3-animate-zoom shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                    <div className="py-2"><p className="mb-0 text-muted">{DateTime.fromSQL(transaction.created_at).toFormat('cccc, dd LLL, yyyy')}</p></div>
                    <div className="position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                    </svg>
                                </div>
                                <p className="mb-0 ps-2 text-muted">Pick Up: <span className="text-dark fw-bold">{order.pickup_address}</span></p>
                            </div>
                            <div className="d-flex justify-content-center align-items-center w3-light-grey send-container w3-circle">
                                <i className="bi bi-arrow-up-right fs-3 text-success"></i>
                            </div>
                        </div>
                        {/* <i className="bi bi-three-dots-vertical text-muted p-0 m-0 fs-3"></i> */}
                        {/* <div className="vertical-line"></div> */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                                <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                <p className="mb-0 ps-2 text-muted">Drop Off: <span className="text-dark fw-bold">{order.receiver_address}</span></p>
                            </div>
                            <div>
                                <p className="mb-0 text-success fs-6">&#8358;&nbsp;{NumberToMoney(transaction.amount)}</p>
                            </div>
                        </div>

                    </div>
                </div>
            )
        }
    }

    const toggleBalanceVisibility = () => {
        setHideBalance(!hideBalance);
    };

    return (
        <>
            <div className="w3-animate-left">
                <div className='bg-primary w-100 pb-3' style={{ borderRadius: "0 0 16px 16px" }}>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="#">
                                {/* <i className="bi bi-arrow-left text-light fw-bolder"></i> */}
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'> </h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                    <div className="my-3">
                        <div>
                            <p className="text-center mb-0 text-light">Wallet Balance</p>
                        </div>
                        <div className="d-flex align-items-center justify-content-center">
                            <h2 className="text-center text-light my-0 fw-normal">
                                {hideBalance ? "*".repeat(NumberToMoney(+balance.toFixed(2)).toString().length) : (<span>&#8358;{NumberToMoney(+balance.toFixed(2))}</span>)}
                            </h2>
                            <i
                                className={`bi ${hideBalance ? "bi-eye" : "bi-eye-slash"} ms-2 text-light fs-2`}
                                onClick={toggleBalanceVisibility}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </div>
                    </div>
                    <div className="px-3">
                        <div className="d-flex justify-content-around align-items-center">
                            {/* <div className="wallet-item bg-light w3-round-large w3-center px-3 py-1">
                                <i className="bi bi-wallet2 fs-1 text-primary fw-bold"></i>
                                <p className="mb-0 text-center text-primary fw-normal">Fund Wallet</p>
                            </div> */}
                            <Link to="/wallet/withdraw">
                                <div className="wallet-item bg-light w3-round-large w3-center px-4 py-1">
                                    <i className="bi bi-credit-card fs-1 text-primary fw-bold"></i>
                                    <p className="mb-0 text-center text-primary fw-normal">Withdraw</p>
                                </div>
                            </Link>
                            <Link to="/wallet/transaction">
                                <div className="wallet-item bg-light w3-round-large w3-center px-3 py-1">
                                    <i className="bi bi-currency-exchange fs-1 text-primary fw-bold"></i>
                                    <p className="mb-0 text-center text-primary fw-normal">Transactions</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container mt-4 px-2" style={{ marginBottom: "100px" }}>
                    {
                        transactions.length > 0 ? (
                            <>
                                <div className="mb-3 d-flex justify-content-between align-items-center">
                                    <h3 className="my-0 ps-2 fw-bold">Recent Transaction</h3> <i className="bi bi-funnel text-dark fs-2 pe-3"></i>
                                </div>
                                {
                                    transactions.slice(0, 5).map((transaction: any) => formatTransaction(transaction))
                                }
                            </>
                        ) : (
                            <>
                            </>
                        )
                    }



                </div>
            </div>
            <Menu />
        </>
    );
};

export default Wallet;
