import React from 'react';
import Menu from './Menu';
import userPhoto from '../../assets/images/photo.png';
import { useMutation, useQuery } from '@apollo/client';
import { UPDATE_PROFILE, LOGOUT } from '../../GraphQL/Mutations';
import { CURRENT_USER_COURIER } from '../../GraphQL/Queries';
import { Link, useNavigate } from 'react-router-dom';
import Session from '../../Helpers/Session';
import { BLOCKBUTTON, BUTTON, INPUT } from '../../Components/Forms';
import UserContext from '../../Helpers/UserContext';
import { Validate } from '../../Helpers/Validate';
import { Loading } from '../../Components/Loading';
import { APIResponse } from '../../Helpers/General';
import { getImage } from '../../Helpers/FileHandling';

const Account: React.FC = () => {
    const [step, setStep] = React.useState<number>(1);
    const navigate = useNavigate();
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [photo, setPhoto] = React.useState<string | null>(user?.photo || userPhoto);
    const [fullname, setFullname] = React.useState<string>(user?.fullname || '');
    const [phone, setPhone] = React.useState<string>(user?.phone || '');
    const [handleModel, setHandleModel] = React.useState<boolean>(false);

    const refreshUser = useQuery(CURRENT_USER_COURIER, {
        fetchPolicy: 'network-only',
        onCompleted: data => {
            Session.setCookie('user', JSON.stringify(data.currentUserCourier));
            Session.set("user", data.currentUserCourier)
            myContext.setUser(data.currentUserCourier);
            setFullname(data.currentUserCourier.fullname);
            setPhoto(data.currentUserCourier.photo);
            setPhone('0' + data.currentUserCourier.phone.substring(3));
        }
    });

    const [updateProfile] = useMutation(UPDATE_PROFILE, {
        variables: {
            fullname: fullname,
            phoneNumber: phone,
            photo: photo
        },
        onCompleted: (data) => {
            refreshUser.refetch();
            Session.showAlert({ str: 'Profile updated successfully.', type: 'success' });
            setStep(1);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setPhoto(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSelectPhoto = (event: any) => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const handleUpdateProfile = () => {
        if (!fullname || !Validate.string(fullname) || fullname.length < 3) {
            Session.showAlert({ str: 'Please enter your fullname.', type: 'error' });
            return;
        }
        if (!phone || !Validate.phone(phone)) {
            Session.showAlert({ str: 'Please enter your valid phone number.', type: 'error' });
            return;
        }
        if (!photo) {
            setPhoto(null);
        }
        updateProfile();
    };

    const [logout, { loading: logoutLoading }] = useMutation(LOGOUT, {
        onCompleted: (data) => {
            setHandleModel(false);
            Session.clearAllCookies();
            Session.removeAll();
            myContext.setUser(null); // Clear user context
            navigate('/login');
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
        }
    });

    const handleLogout = () => {
        logout();
    };

    if (logoutLoading) {
        return (
            <Loading fullPage={true} message="LOGGING OUT" timing={false} />
        );
    }

    return (
        <>
            <div className=''>
                {step === 1 && (
                    <div className='w3-animate-left'>
                        <div className='bg-primary'>
                            <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                                <div></div>
                                <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                                    <h3 className='w3-center text-light mb-0'>Account</h3>
                                </div>
                                <div className='me-3'></div>
                            </div>
                        </div>
                        <div className="px-3">
                            <div className='d-flex align-items-center justify-content-between mt-3 py-3'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <img
                                        src={photo ? getImage(photo) : userPhoto}
                                        alt=""
                                        className='rounded-circle'
                                        style={{ width: '50px', height: '50px', objectFit: 'cover' }}
                                    />
                                    <h3 className='my-0 ms-3 fs-5'>{fullname.split(" ").slice(0, 2).join(" ")}</h3>
                                </div>

                                <BUTTON
                                    className='btn btn-primary my-0 py-3'
                                    onClick={() => setStep(2)}
                                >
                                    Edit Profile
                                </BUTTON>
                            </div>
                            <div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-3 w3-animate-zoom'
                                    onClick={() => navigate('/faqs')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-chat-square-dots text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>FAQs</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/managevehicle')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-star text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Manage Vehicles</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/managedocuments')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-file-earmark-text text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Manage Documents</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/support')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-headset text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Customer Support</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => navigate('/policy')}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-clipboard-data text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Privacy & Policy</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                                <div
                                    className='d-flex align-items-center justify-content-between account-details py-3 pe-2 ps-3 mt-2 w3-animate-zoom'
                                    onClick={() => setHandleModel(true)}
                                    style={{ marginBottom: '80px' }}
                                >
                                    <div className='d-flex align-items-center'>
                                        <i className="bi bi-box-arrow-right text-primary" style={{ fontSize: '30px' }}></i>
                                        <p className='fw-bold ps-4 mb-0'>Sign Out</p>
                                    </div>
                                    <i className="bi bi-chevron-right fs-3 fw-bold"></i>
                                </div>
                            </div>
                        </div>
                        <Menu />
                        <div
                            className={'brand-modal w3-animate-zoom ' + (handleModel ? 'd-flex' : 'd-none')}
                            style={{ alignItems: 'center' }}
                            onClick={() => setHandleModel(false)}
                        >
                            <div
                                className='container bg-light p-3 mx-3'
                                style={{ borderRadius: '16px', maxWidth: '400px' }}
                            >
                                <h3 className='text-center'>Sign Out</h3>
                                <div>
                                    <p className='text-center text-wrap'>
                                        Are you sure you want to sign out?
                                    </p>
                                </div>
                                <div className='d-flex justify-content-around align-items-center'>
                                    <BUTTON
                                        className='btn border border-dark mt-4 mb-3'
                                        onClick={() => setHandleModel(false)}
                                    >
                                        Cancel
                                    </BUTTON>
                                    <BUTTON
                                        className='btn brand negative mt-4 mb-3'
                                        onClick={handleLogout}
                                    >
                                        Sign Out
                                    </BUTTON>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {step === 2 && (
                    <div className='w3-animate-left'>
                        <div className='bg-primary'>
                            <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                                <div>
                                    <Link className="dark fs-3 ps-3" onClick={() => setStep(1)} to="#">
                                        <i className="bi bi-arrow-left text-light fw-bolder"></i>
                                    </Link>
                                </div>
                                <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                                    <h3 className='w3-center text-light mb-0'>Edit Profile</h3>
                                </div>
                                <div className='me-3'></div>
                            </div>
                        </div>
                        <div className="container">
                            <div
                                className='border border-primary rounded-circle mx-auto mt-5 d-flex align-items-center justify-content-center position-relative'
                                style={{ width: '150px', height: '150px' }}
                            >
                                <img
                                    src={photo ? getImage(photo) : userPhoto}
                                    className="w-100 h-100 rounded-circle w3-image w3-animate-zoom w3-hover-shadow position-relative"
                                    alt=''
                                    onClick={(event) => handleSelectPhoto(event)}
                                />
                                <i className="bi bi-pencil-square text-primary fs-3 position-absolute bottom-0 end-0 py-1 px-2 w3-sand w3-circle w3-hover-shadow w3-animate-zoom"></i>
                            </div>
                            <label className='mt-3'>Full Name</label>
                            <INPUT
                                type="text"
                                value={fullname}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFullname(e.target.value)}
                                placeholder="Enter full name"
                            />
                            <label className='mt-3'>Phone Number</label>
                            <INPUT
                                type="text"
                                value={phone}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
                                placeholder="Enter phone number"
                                maxLength={11}
                            />
                            <BLOCKBUTTON
                                className='btn btn-primary mt-5'
                                onClick={handleUpdateProfile}
                            >
                                Save Changes
                            </BLOCKBUTTON>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default Account;
