import React from "react";
import { Link } from "react-router-dom";
import { BLOCKBUTTON, INPUT } from "../../../Components/Forms";
import UserContext from "../../../Helpers/UserContext";
import { UPLOAD_BIKE_DETAILS, DELETE_COURIER_VEHICLE_DETAILS } from "../../../GraphQL/Mutations";
import { useMutation } from "@apollo/client";
import Session from "../../../Helpers/Session";
import { Validate } from "../../../Helpers/Validate";
import { Loading } from "../../../Components/Loading";
import { getImage } from "../../../Helpers/FileHandling";
import Confirm from "../../../Components/Confirm";



const ManageVehicle = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [step, setStep] = React.useState(1)
    const [bike, setBike] = React.useState(user?.courierDetails?.bike_photo || '');
    const [bikeBrand, setBikeBrand] = React.useState(user?.courierDetails?.bike_brand || '');
    const [bikeModel, setBikeModel] = React.useState(user?.courierDetails?.bike_model || '');
    const [bikeYear, setBikeYear] = React.useState<number>(user?.courierDetails?.bike_year || 0);
    const [bikePlateNo, setBikePlateNo] = React.useState(user?.courierDetails?.bike_plate_number || '');
    const [selectImage, setSelectImage] = React.useState(false);
    const [confirmDelete, setConfirmDelete] = React.useState(false);
    const [confirmEdit, setConfirmEdit] = React.useState(false);
    const [editSuccess, setEditSuccess] = React.useState(false);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setBike(reader.result as string);
                setSelectImage(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const openCamera = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.capture = 'camera';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openGallery = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openDocuments = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };
    const [uploadBikeDetails, { loading: uploadBikeDetailsLoading }] = useMutation(UPLOAD_BIKE_DETAILS, {
        variables: {
            bikePhoto: bike || "",
            bikeBrand: bikeBrand,
            bikeModel: bikeModel,
            bikeYear: bikeYear,
            bikePlateNumber: bikePlateNo
        },
        onCompleted: () => {
            Session.showAlert({ str: 'Bike details uploaded successfully', type: 'success' });
            setConfirmEdit(false);
            setEditSuccess(true);
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const [deleteVehicleDetails, { loading: deleteVehicleDetailsLoading }] = useMutation(DELETE_COURIER_VEHICLE_DETAILS, {
        onCompleted: () => {
            Session.showAlert({ str: 'Bike details deleted successfully', type: 'success' });
            setConfirmDelete(false);
            window.location.reload();
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const handleBikeUpload = async () => {
        if (!bike) {
            Session.showAlert({ str: 'Please upload your bike photo', type: 'warning' });
            return;
        }
        if (!bikeBrand || !Validate.string(bikeBrand)) {
            Session.showAlert({ str: 'Please enter your bike brand', type: 'warning' });
            return;
        }
        if (!bikeModel || !Validate.string(bikeModel)) {
            Session.showAlert({ str: 'Please enter your bike model', type: 'warning' });
            return;
        }
        if (!bikeYear || !Validate.positiveInteger(+bikeYear)) {
            Session.showAlert({ str: 'Please enter your bike year', type: 'warning' });
            return;
        }
        if (!bikePlateNo || !Validate.string(bikePlateNo)) {
            Session.showAlert({ str: 'Please enter your bike plate number', type: 'warning' });
            return;
        }
        await uploadBikeDetails();
    }

    if (uploadBikeDetailsLoading || deleteVehicleDetailsLoading) {
        return <Loading fullPage={false} message={'Uploading bike details'} timing={false} />
    }
    return (
        <>
            <div className="w3-animate-left">
                <div className='bg-primary w-100'>
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            <Link className="dark fs-3 ps-3" to="/account"
                            >
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link>
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Manage Vehicle</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                {step === 1 && (
                    <>
                        <div className="container mt-3">
                            <div className="w3-round-large pending p-3">
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className="mb-0 fw-bolder">{bikeModel}</p>
                                    <p className="mb-0 py-2 px-3 w3-round-xlarge bg-light text-negative fw-normal">{bikeBrand}</p>
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-3">
                                    <div className="">
                                        <p className="mb-0">{bikePlateNo}</p>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <i className="bi bi-pencil-square fs-3 text-success me-4"
                                            onClick={() => setStep(2)}
                                        ></i>
                                        <i className="bi bi-trash fs-3 text-negative"
                                            onClick={() => setConfirmDelete(true)}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {confirmDelete && (
                            <Confirm key="delete" message={<>
                                <span>Are you sure you want to delete your vehicle?</span><br />
                                <span className="fw-bold text-muted">This will make your account Inactive.</span>
                            </>} onConfirm={() => deleteVehicleDetails()} onCancel={() => setConfirmDelete(false)} topic="Delete Vehicle?" cancelText="Cancel" takeActionText="Delete" />
                        )}
                    </>

                )}
                {
                    step === 2 && (
                        <>
                            <div className="container">
                                <div className='mt-4'>
                                    <p className='mb-0'>Upload a picture of your bike.</p>
                                    <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                        onClick={() => {
                                            setSelectImage(true);
                                        }}
                                    >
                                        {bike ? (
                                            <img src={getImage(bike)} alt="Bike" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                        ) : (
                                            <div className='w3-center'>
                                                <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center"></i>
                                                <p className='text-muted'>Upload Picture</p>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <label className='mt-3'>Bike Brand</label>
                                <INPUT type="text" value={bikeBrand} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeBrand(e.target.value)} placeholder="Enter brand" />

                                <label className='mt-3'>Bike Model</label>
                                <INPUT type="text" value={bikeModel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeModel(e.target.value)} placeholder="Enter model" />

                                <label className='mt-3'>Bike Year</label>
                                <INPUT type="number" value={bikeYear} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeYear(+e.target.value)} placeholder="Enter year" />

                                <label className='mt-3'>Bike Plate Number</label>
                                <INPUT type="text" value={bikePlateNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikePlateNo(e.target.value)} placeholder="Enter plate number" />

                                <BLOCKBUTTON
                                    className={'btn mt-5 mb-2 ' + (!bike && !bikeBrand && !bikeModel && !bikeYear && !bikePlateNo ? 'btn-inactive-primary' : 'btn-primary')}
                                    onClick={() => setConfirmEdit(true)}
                                >Continue</BLOCKBUTTON>
                            </div>
                            {confirmEdit && (
                                <Confirm key="edit" message={<>
                                    <span>Are you sure you want to edit your bike details?</span><br />
                                    <span className="fw-bold text-muted">This will make your account Suspended, you will need to contact customer support to re-activate your account.</span>
                                </>} onConfirm={() => handleBikeUpload()} onCancel={() => setConfirmEdit(false)} topic="Edit Bike Details?" cancelText="Cancel  " takeActionText="Save Changes" />
                            )}
                            {editSuccess && (
                                <div className={'brand-modal w3-animate-zoom d-flex align-items-center'}>
                                    <div className='w-100 bg-light px-3 mx-2' style={{ borderRadius: '16px', maxWidth: '400px' }}>

                                        <h3 className='w3-center text-warning fs-3 fw-bold mt-5 mb-3'>Review Ongoing</h3>

                                        <p className='text-center mb-0 text-muted'>Your Bike details have been successfully updated, and are currently under review. You will be notified once your account has been approved.</p>

                                        <BLOCKBUTTON
                                            className="btn-primary mb-4 mt-5"
                                            onClick={() => {
                                                setEditSuccess(false);
                                                window.location.reload();
                                            }}
                                        >
                                            Continue
                                        </BLOCKBUTTON>
                                    </div>
                                </div>
                            )}
                        </>
                    )
                }
                <div className={'brand-modal w3-animate-bottom ' + (selectImage ? 'd-flex' : 'd-none')}
                    onClick={() => setSelectImage(false)}
                >
                    <div className='container brand-modal-content bg-light'>
                        <h4 className='text-center py-3'>Choose From</h4>
                        <div className='d-flex justify-content-around align-items-center'>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openCamera}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-camera fs-3 text-muted"></i>
                                    <p>Camera</p>
                                </div>
                            </div>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openGallery}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-images fs-3 text-muted"></i>
                                    <p>Gallery</p>
                                </div>
                            </div>
                            <div className='w3-center icon-shadow p-2 w3-round-large'
                                onClick={openDocuments}
                            >
                                <div className='d-flex flex-column'>
                                    <i className="bi bi-file-earmark-richtext text-muted fs-3"></i>
                                    <p>Documents</p>
                                </div>
                            </div>
                        </div>
                        <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="fileInput" />
                        <BLOCKBUTTON
                            className='btn btn-primary mt-4 mb-3'
                            onClick={() => document.getElementById('fileInput')?.click()}
                        >Continue</BLOCKBUTTON>
                    </div>

                </div>
            </div>

        </>
    )
}

export default ManageVehicle;