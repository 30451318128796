import React from 'react';
import { BLOCKBUTTON, BUTTON, INPUT } from '../../Components/Forms';
import { Link, useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { UPLOAD_BIKE_DETAILS, UPLOAD_COURIER_NIN_AND_LICENSE } from '../../GraphQL/Mutations';
import { Validate } from '../../Helpers/Validate';
import Session from '../../Helpers/Session';
import UserContext from '../../Helpers/UserContext';
import Menu from '../MenuPages/Menu';
import { Loading } from '../../Components/Loading';
import { getImage } from '../../Helpers/FileHandling';

const Verification: React.FC = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [licenseFront, setLicenseFront] = React.useState('');
    const [licenseBack, setLicenseBack] = React.useState('');
    const [nin, setNin] = React.useState('');
    const [bike, setBike] = React.useState(user?.courierDetails?.bike_photo || '');
    const [bikeBrand, setBikeBrand] = React.useState(user?.courierDetails?.bike_brand || '');
    const [bikeModel, setBikeModel] = React.useState(user?.courierDetails?.bike_model || '');
    const [bikeYear, setBikeYear] = React.useState<number>(user?.courierDetails?.bike_year || 0);
    const [bikePlateNo, setBikePlateNo] = React.useState(user?.courierDetails?.bike_plate_number || '');
    const [bikeDetailStatus, setBikeDetailStatus] = React.useState(user?.courierDetails?.status || 'Inactive');
    const [ninStatus, setNinStatus] = React.useState(user?.courierDocumentStatus?.nin_status || '');
    const [licenseStatus, setLicenseStatus] = React.useState(user?.courierDocumentStatus?.riders_license_status || '');
    const [step, setStep] = React.useState(1);
    const [selectImage, setSelectImage] = React.useState(false);
    const [selectedImageType, setSelectedImageType] = React.useState<string | null>(null);
    const [accStatusModal, setAccStatusModal] = React.useState(false);
    const navigate = useNavigate();

    const isVerified = () => {
        return ninStatus === 'APPROVED' && licenseStatus === 'APPROVED' && bikeDetailStatus === 'Active';
    }

    const isPending = () => {
        return ninStatus === 'PENDING' || licenseStatus === 'PENDING'
    }
    const isbikeDetailStatusSuspended = () => {
        return bikeDetailStatus === "Suspended";
    }
    const isDocumentRejected = () => {
        return ninStatus === 'REJECTED' || licenseStatus === 'REJECTED'
    }
    const isDocumentNotUploaded = () => {
        return ninStatus === 'NOT_UPLOADED' || licenseStatus === 'NOT_UPLOADED'
    }
    const getAccStatus = () => {
        if (ninStatus === 'APPROVED' && licenseStatus === 'APPROVED' && bikeDetailStatus === 'Active') {
            return {
                heading: "Account Approved",
                message: "Your account is now approved, you can begin to receive delivery requests and earning with GOkari",
                text_color: 'text-success'
            }
        } else if (ninStatus === 'PENDING' || licenseStatus === 'PENDING' || bikeDetailStatus === 'Suspended') {
            return {
                heading: "Review Ongoing",
                message: 'Your documents have been successfully uploaded and are currently under review. You will be notified once your account has been approved.',
                text_color: 'text-warning'
            };
        } else if (ninStatus === 'REJECTED' || licenseStatus === 'REJECTED') {
            return {
                heading: "Account Not Approved",
                message: "We're sorry, but your account was not approved. Kindly reupload your documents and details correctly to continue the process.",
                text_color: 'text-negative'
            };
        } else {
            return {
                heading: "Upload Your Documents",
                message: "Please upload your documents and details to continue the process.",
                text_color: 'text-negative'
            };
        }
    };
    const accStatus = getAccStatus();
    const [uploadBikeDetails, { loading: uploadBikeDetailsLoading }] = useMutation(UPLOAD_BIKE_DETAILS, {
        variables: {
            bikePhoto: bike,
            bikeBrand: bikeBrand,
            bikeModel: bikeModel,
            bikeYear: bikeYear,
            bikePlateNumber: bikePlateNo
        },
        onCompleted: () => {
            window.location.reload();
            Session.showAlert({ str: 'Bike details uploaded successfully', type: 'success' });
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });
    const [uploadNinAndLicense, { loading: uploadNinAndLicenseLoading }] = useMutation(UPLOAD_COURIER_NIN_AND_LICENSE, {
        variables: {
            ridersLicenseFront: licenseFront,
            ridersLicenseBack: licenseBack,
            nin: nin
        },
        onCompleted: (data) => {
            if (data.uploadCourierLicenseAndNIN) {
                window.location.reload();
                Session.showAlert({ str: 'NIN and license uploaded successfully', type: 'success' });
            } else {
                Session.showAlert({ str: 'Something went wrong', type: 'error' });
                return;
            }
        },
        onError: (error) => {
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    })

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                if (selectedImageType === 'licenseFront') {
                    setLicenseFront(reader.result as string);
                } else if (selectedImageType === 'licenseBack') {
                    setLicenseBack(reader.result as string);
                } else if (selectedImageType === 'nin') {
                    setNin(reader.result as string);
                } else if (selectedImageType === 'bike') {
                    setBike(reader.result as string);
                }
                setSelectImage(false);
            };
            reader.readAsDataURL(file);
        }
    };

    const openCamera = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.capture = 'camera';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openGallery = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };

    const openDocuments = () => {
        const input: any = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = handleImageUpload;
        input.click();
    };
    if (uploadBikeDetailsLoading) {
        return <Loading fullPage={false} message={'Uploading bike details'} timing={false} />
    }
    if (uploadNinAndLicenseLoading) {
        return <Loading fullPage={false} message={'Uploading bike details'} timing={false} />
    }
    const handleBikeUpload = async () => {
        if (!bike) {
            Session.showAlert({ str: 'Please upload your bike photo', type: 'warning' });
            return;
        }
        if (!bikeBrand || !Validate.string(bikeBrand)) {
            Session.showAlert({ str: 'Please enter your bike brand', type: 'warning' });
            return;
        }
        if (!bikeModel || !Validate.string(bikeModel)) {
            Session.showAlert({ str: 'Please enter your bike model', type: 'warning' });
            return;
        }
        if (!bikeYear || !Validate.positiveInteger(+bikeYear)) {
            Session.showAlert({ str: 'Please enter your bike year', type: 'warning' });
            return;
        }
        if (!bikePlateNo || !Validate.string(bikePlateNo)) {
            Session.showAlert({ str: 'Please enter your bike plate number', type: 'warning' });
            return;
        }
        await uploadBikeDetails();
    }
    const handleLicenceAndNINUpload = async () => {
        if (!licenseFront) {
            Session.showAlert({ str: 'Please upload your license front', type: 'warning' });
            return;
        }
        if (!licenseBack) {
            Session.showAlert({ str: 'Please upload your license back', type: 'warning' });
            return;
        }
        if (!nin) {
            Session.showAlert({ str: 'Please upload your NIN', type: 'warning' });
            return;
        }
        await uploadNinAndLicense();
    }

    return (
        <>
            <div className=''>
                {
                    step === 1 && (
                        <div className='w3-animate-left'>
                            <div className='bg-primary'>
                                <div className='d-flex align-items-center justify-content-between pt-4 pb-1'>
                                    <div>

                                    </div>
                                    <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                                        <h3 className='w3-center text-light my-0'>Gokari</h3>
                                    </div>
                                    <div className='me-3'
                                        onClick={() => navigate('/notification')}
                                    >
                                        <i className="bi bi-bell fs-3 text-end text-light"></i>
                                    </div>
                                </div>
                            </div>
                            <div className='container'>
                                {!isVerified() ? (
                                    <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-light-grey w3-round-xlarge'>
                                        <div className=''>
                                            <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                            <h3 className='d-inline'>Profile Registration Pending</h3>
                                        </div>
                                        <p className='text-negative mt-2 fw-bold'>Pending</p>
                                    </div>
                                ) : (
                                    <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-light-grey w3-round-xlarge'>
                                        <div className=''>
                                            <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                            <h3 className='d-inline'>Profile Registration Successful</h3>
                                        </div>
                                        <p className='text-success p-2 mt-2 d-inline-block w3-round-xxlarge fw-bold approved'>Approved</p>
                                    </div>
                                )}

                                {(() => {
                                    if (isDocumentNotUploaded() || isDocumentRejected()) {
                                        return (
                                            <div className='brand-card pb-2 pt-3 px-3 my-3 w3-animate-zoom w3-light-grey w3-round-xlarge'>
                                                <div className=''>
                                                    <h3 className='d-inline'>{isDocumentNotUploaded() ? 'Upload Documents' : 'Documents Rejected'}</h3>
                                                </div>
                                                <p className='text-muted mt-2 text-wrap'>We need to verify your documents to activate your account.</p>
                                                <Link onClick={() => setStep(2)} to="#">
                                                    <BUTTON className='btn btn-primary'>
                                                        Upload Documents
                                                    </BUTTON>
                                                </Link>
                                            </div>
                                        );
                                    } else if (ninStatus === "PENDING" || licenseStatus === "PENDING") {
                                        return (
                                            <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-round-xlarge pending'>
                                                <div className=''>
                                                    <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                                    <h3 className='d-inline'>Documents Uploaded Successfully</h3>
                                                </div>
                                                <p className='text-primary p-2 mt-2 d-inline-block w3-round-xxlarge fw-bold bg-light'>Under Review</p>
                                            </div>
                                        );
                                    } else if (ninStatus === "APPROVED" || licenseStatus === "APPROVED") {
                                        return (
                                            <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-round-xlarge w3-light-grey'>
                                                <div className=''>
                                                    <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                                    <h3 className='d-inline'>Documents Uploaded Successfully.</h3>
                                                </div>
                                                <p className='text-success p-2 mt-2 d-inline-block w3-round-xxlarge fw-bold' style={{ backgroundColor: "#dcfce7" }}>Approved</p>
                                            </div>
                                        );
                                    }
                                    return (
                                        <div className='brand-card pb-2 pt-3 px-3 my-3 w3-animate-zoom w3-light-grey w3-round-xlarge'>
                                            <div className=''>
                                                <h3 className='d-inline'>Upload Documents</h3>
                                            </div>
                                            <p className='text-muted mt-2 text-wrap'>We need to verify your documents to activate your account.</p>
                                            <Link onClick={() => setStep(2)} to="#">
                                                <BUTTON className='btn btn-primary'>
                                                    Upload Documents
                                                </BUTTON>
                                            </Link>
                                        </div>
                                    );
                                })()}

                                {bikeDetailStatus === 'Active' ? (
                                    <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-round-xlarge w3-light-grey' >
                                        <div className=''>
                                            <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                            <h3 className='d-inline'>Vehicle Added Successfully.</h3>
                                        </div>
                                        <p className='text-success p-2 mt-2 d-inline-block w3-round-xxlarge fw-bold' style={{ backgroundColor: "#dcfce7" }}>Approved</p>
                                    </div>

                                ) : (
                                    <>
                                        {
                                            bikeDetailStatus === 'Suspended' ? (
                                                <div className='brand-card pb-2 pt-3 ps-3 my-3 w3-animate-zoom w3-round-xlarge pending'>
                                                    <div className=''>
                                                        <i className="bi bi-check-circle-fill text-primary pe-3 fs-3 d-inline"></i>
                                                        <h3 className='d-inline'>Vehicle's documents Added Successfully.</h3>
                                                    </div>
                                                    <p className='text-primary p-2 mt-2 d-inline-block w3-round-xxlarge fw-bold bg-light'>Under Review</p>
                                                </div>
                                            ) : (
                                                <><div className='brand-card pb-2 pt-3 px-3 my-3 w3-animate-zoom w3-light-grey w3-round-xlarge'>
                                                    <div className=''>
                                                        <h3 className='d-inline'>Add Vehicle's documents</h3>
                                                    </div>
                                                    <p className='text-muted mt-2 text-wrap'>Please submit your vehicle and its required documents for us to verify your vehicle registration.</p>
                                                    <Link onClick={() => setStep(3)} to="#">
                                                        <BUTTON className='btn btn-primary'>
                                                            Add Vehicle
                                                        </BUTTON>
                                                    </Link>
                                                </div></>
                                            )}
                                    </>

                                )}

                                <div
                                    className='container position-fixed bottom-0 start-50 translate-middle-x w-100 d-flex justify-content-center pb-2 bg-light'
                                    style={{ marginBottom: "80px" }}
                                >
                                    <BLOCKBUTTON className='btn btn-primary mt-4 mb-2'
                                        onClick={() => setAccStatusModal(true)}
                                        style={{ marginBottom: "80px" }}
                                    >
                                        Check Account Status
                                    </BLOCKBUTTON>
                                </div>

                            </div>
                            <Menu />
                            <div className={'brand-modal w3-animate-bottom ' + (selectImage ? 'd-flex' : 'd-none')}
                                style={{ alignItems: 'center' }}
                                onClick={() => setSelectImage(false)}
                            >
                                <div className='container bg-light p-3 mx-3'
                                    style={{ borderRadius: '16px', maxWidth: "400px" }}
                                >
                                    <h3 className='text-center'>Review ongoing</h3>
                                    <div>
                                        <p className='text-center text-wrap'>
                                            Your documents have been Successfully uploaded and are currently under review. You will be notified once your account has been approved.
                                        </p>
                                    </div>
                                    <BLOCKBUTTON
                                        className='btn btn-primary mt-4 mb-3'
                                        onClick={() => setSelectImage(false)}
                                    >Go back</BLOCKBUTTON>
                                </div>

                            </div>
                            <div
                                className={'brand-modal w3-animate-zoom ' + (accStatusModal ? 'd-flex' : 'd-none')}
                                style={{ alignItems: 'center' }}
                                onClick={() => setAccStatusModal(false)}
                            >
                                <div
                                    className='container bg-light p-3 mx-3'
                                    style={{ borderRadius: '16px', maxWidth: '400px' }}
                                >
                                    <h3 className={'text-center fw-bold ' + (accStatus.text_color)}>{accStatus.heading}</h3>
                                    <div>
                                        <p className='text-center text-wrap'>
                                            {accStatus.message}
                                        </p>
                                    </div>
                                    <div className='d-flex justify-content-around align-items-center'>
                                        <BLOCKBUTTON
                                            className='btn btn-primary mt-4 mb-3'
                                            onClick={() => setAccStatusModal(false)}
                                        >
                                            Go Back
                                        </BLOCKBUTTON>

                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    step === 2 && (
                        <div className='w3-animate-left'>
                            <div className='bg-primary'>
                                <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>

                                    <Link className="fs-3 text-light ms-3" onClick={() => setStep(1)} to="#">
                                        <i className="bi bi-arrow-left"></i>
                                    </Link>
                                    <h3 className='w3-center text-light my-0'>Upload documents</h3>

                                    <div className='me-3'>
                                        {/* <i className="bi bi-bell fs-3 text-end text-light"></i> */}
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className=''>
                                    <div className='mt-4'>
                                        <p className='mb-0'>Upload a picture of your rider's license (Front).</p>
                                        <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                            onClick={() => {
                                                setSelectImage(true);
                                                setSelectedImageType('licenseFront');
                                            }}
                                        >
                                            {licenseFront ? (
                                                <img src={getImage(licenseFront)} alt="License Front" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                            ) : (
                                                <div className='w3-center'>
                                                    <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center"></i>
                                                    <p className='text-muted'>Upload Picture</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='mb-0'>Upload a picture of your rider's license (Back).</p>
                                        <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                            onClick={() => {
                                                setSelectImage(true);
                                                setSelectedImageType('licenseBack');
                                            }}
                                        >
                                            {licenseBack ? (
                                                <img src={getImage(licenseBack)} alt="License Back" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                            ) : (
                                                <div className='w3-center'>
                                                    <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center mb-0"></i>
                                                    <p className='text-muted'>Upload Picture</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <p className='mb-0'>Upload a picture of your NIN Card.</p>
                                        <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                            onClick={() => {
                                                setSelectImage(true);
                                                setSelectedImageType('nin');
                                            }}
                                        >
                                            {nin ? (
                                                <img src={getImage(nin)} alt="NIN Card" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                            ) : (
                                                <div className='w3-center'>
                                                    <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center mb-0"></i>
                                                    <p className='text-muted'>Upload Picture</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!isPending() && (
                                    <BLOCKBUTTON className={'btn my-3 ' + (licenseFront && licenseBack && nin ? 'btn-primary' : 'btn-inactive-primary')}
                                        onClick={handleLicenceAndNINUpload}
                                    >
                                        Submit
                                    </BLOCKBUTTON>
                                )}

                            </div>

                            <div className={'brand-modal w3-animate-bottom ' + (selectImage && !isPending() ? 'd-flex' : 'd-none')}
                                onClick={() => setSelectImage(false)}
                            >
                                <div className='container brand-modal-content bg-light'>
                                    <h4 className='text-center py-3'>Choose From</h4>
                                    <div className='d-flex justify-content-around align-items-center'>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openCamera}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-camera fs-3 text-muted"></i>
                                                <p>Camera</p>
                                            </div>
                                        </div>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openGallery}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-images fs-3 text-muted"></i>
                                                <p>Gallery</p>
                                            </div>
                                        </div>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openDocuments}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-file-earmark-richtext text-muted fs-3"></i>
                                                <p>Documents</p>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="fileInput" />
                                    <BLOCKBUTTON
                                        className='btn btn-primary mt-4 mb-3'
                                        onClick={() => document.getElementById('fileInput')?.click()}
                                    >Continue</BLOCKBUTTON>
                                </div>

                            </div>
                        </div>

                    )
                }
                {
                    step === 3 && (
                        <div className="w3-animate-left">
                            <div className='bg-primary'>
                                <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>

                                    <Link className="fs-3 text-light ms-3" onClick={() => setStep(1)} to="#">
                                        <i className="bi bi-arrow-left"></i>
                                    </Link>
                                    <h3 className='w3-center text-light my-0'>Add Vehicle</h3>

                                    <div className='me-3'>
                                        {/* <i className="bi bi-bell fs-3 text-end text-light"></i> */}
                                    </div>
                                </div>
                            </div>
                            <div className="container">
                                <div className=''>
                                    <div className='mt-4'>
                                        <p className='mb-0'>Upload a picture of your bike.</p>
                                        <div className='brand-card w3-animate-zoom w3-light-grey w3-round-xlarge border border-primary border-dashed upload-card d-flex justify-content-center align-items-center'
                                            onClick={() => {
                                                setSelectImage(true);
                                                setSelectedImageType('bike');
                                            }}
                                        >
                                            {bike ? (
                                                <img src={getImage(bike)} alt="Bike" className="w-100 h-100 w3-round-xlarge" style={{ objectFit: 'cover' }} />
                                            ) : (
                                                <div className='w3-center'>
                                                    <i className="bi bi-credit-card-2-front-fill upload-card-icon text-center"></i>
                                                    <p className='text-muted'>Upload Picture</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <label className='mt-3'>Bike Brand</label>
                                    <INPUT type="text" value={bikeBrand} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeBrand(e.target.value)} placeholder="Enter brand" />

                                    <label className='mt-3'>Bike Model</label>
                                    <INPUT type="text" value={bikeModel} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeModel(e.target.value)} placeholder="Enter model" />

                                    <label className='mt-3'>Bike Year</label>
                                    <INPUT type="number" value={bikeYear} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikeYear(+e.target.value)} placeholder="Enter year" />

                                    <label className='mt-3'>Bike Plate Number</label>
                                    <INPUT type="text" value={bikePlateNo} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBikePlateNo(e.target.value)} placeholder="Enter plate number" />
                                    {!isbikeDetailStatusSuspended() && (
                                        <BLOCKBUTTON
                                            className={'btn mt-5 mb-2 ' + (!bike && !bikeBrand && !bikeModel && !bikeYear && !bikePlateNo ? 'btn-inactive-primary' : 'btn-primary')}
                                            onClick={handleBikeUpload}
                                        >Continue
                                        </BLOCKBUTTON>
                                    )}

                                </div>
                            </div>
                            <div className={'brand-modal w3-animate-bottom ' + (selectImage && !isbikeDetailStatusSuspended() ? 'd-flex' : 'd-none')}
                                onClick={() => setSelectImage(false)}
                            >
                                <div className='container brand-modal-content bg-light'>
                                    <h4 className='text-center py-3'>Choose From</h4>
                                    <div className='d-flex justify-content-around align-items-center'>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openCamera}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-camera fs-3 text-muted"></i>
                                                <p>Camera</p>
                                            </div>
                                        </div>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openGallery}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-images fs-3 text-muted"></i>
                                                <p>Gallery</p>
                                            </div>
                                        </div>
                                        <div className='w3-center icon-shadow p-2 w3-round-large'
                                            onClick={openDocuments}
                                        >
                                            <div className='d-flex flex-column'>
                                                <i className="bi bi-file-earmark-richtext text-muted fs-3"></i>
                                                <p>Documents</p>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="file" accept="image/*" onChange={handleImageUpload} style={{ display: 'none' }} id="fileInput" />
                                    <BLOCKBUTTON
                                        className='btn btn-primary mt-4 mb-3'
                                        onClick={() => document.getElementById('fileInput')?.click()}
                                    >Continue</BLOCKBUTTON>
                                </div>

                            </div>
                        </div>
                    )
                }

            </div>
        </>
    );
};

export default Verification;
