import { gql } from '@apollo/client';

export const LOGIN = gql`
mutation Login($email: String, $token: String) {
  login(email: $email, token: $token) {
    accessToken
    refreshToken
  }
}
`;

export const LOGOUT = gql`
mutation Mutation {
  logout
}
`;

export const SEND_TOKEN = gql`
mutation SendLoginToken($email: String) {
  sendLoginToken(email: $email)
}
`;

export const REGISTER = gql`
mutation Register($fullname: String, $email: String, $phoneNumber: String, $photo: String, $category: String) {
  register(fullname: $fullname, email: $email, phone_number: $phoneNumber, photo: $photo, category: $category) {
    accessToken
    refreshToken
  }
}
`;
export const VALIDATE_EMAIL = gql`
mutation ValidateToken($email: String, $token: String) {
  validateToken(email: $email, token: $token)
}
`;

export const UPDATE_ACCEPTED_TERMS = gql`
mutation UpdateTermsAccepted($email: String) {
  updateTermsAccepted(email: $email)
}
`;
export const UPDATE_FIREBASE_TOKEN = gql`
mutation UpdateFirebaseToken($token: String) {
  updateFirebaseToken(token: $token)
}
`;

export const UPDATE_PROFILE = gql`
mutation UpdateProfile($fullname: String, $phoneNumber: String, $photo: String) {
  updateProfile(fullname: $fullname, phone_number: $phoneNumber, photo: $photo) {
    id
    fullname
    email
    phone
    photo
    balance
    category
    successful_orders
    rating
    chat_details
    created_at
  }
}
`;

export const UPLOAD_BIKE_DETAILS = gql`
mutation UploadBikeDetails($bikePhoto: String, $bikeBrand: String, $bikeModel: String, $bikeYear: Int, $bikePlateNumber: String) {
  uploadBikeDetails(bike_photo: $bikePhoto, bike_brand: $bikeBrand, bike_model: $bikeModel, bike_year: $bikeYear, bike_plate_number: $bikePlateNumber)
}
`;
export const DELETE_COURIER_VEHICLE_DETAILS = gql`
mutation Mutation {
  deleteCourierVehicleDetails
}
`;

export const UPLOAD_COURIER_NIN_AND_LICENSE = gql`
mutation UploadCourierLicenseAndNIN($ridersLicenseFront: String, $ridersLicenseBack: String, $nin: String) {
  uploadCourierLicenseAndNIN(riders_license_front: $ridersLicenseFront, riders_license_back: $ridersLicenseBack, NIN: $nin)
}
`;

export const WITHDRAW_FUNDS = gql`
mutation WithdrawFunds($amount: Float, $accountNumber: String, $accountName: String, $bankName: String, $reference: String) {
  withdrawFunds(amount: $amount, account_number: $accountNumber, account_name: $accountName, bank_name: $bankName, reference: $reference)
}
`;
export const GO_ONLINE = gql`
mutation Mutation {
  goOnline
}
`;
export const GO_OFFLINE = gql`
mutation Mutation {
  goOffline
}
`;
export const UPDATE_CURRENT_LOCATION = gql`
mutation UpdateCurrentLocation($lat: Float, $lng: Float) {
  updateCurrentLocation(lat: $lat, lng: $lng)
}
`;
export const COURIER_ACCEPT_ORDER = gql`
mutation CourierAcceptOrder($orderId: Int) {
  CourierAcceptOrder(order_id: $orderId)
}
`;
export const COURIER_DECLINE_ORDER = gql`
mutation CourierDeclineOrder($orderId: Int) {
  CourierDeclineOrder(order_id: $orderId)
}
`;
export const UPDATE_ORDER_STATUS = gql`
mutation UpdateOrderStatus($orderId: Int, $status: updateOrderStatus) {
  updateOrderStatus(order_id: $orderId, status: $status)
}
`;
export const CONFIRM_ORDER_CODE_AND_COMPLETE = gql`
mutation ConfirmOrderCodeAndComplete($orderId: Int, $code: String) {
  confirmOrderCodeAndComplete(order_id: $orderId, code: $code)
}
`;
export const CANCEL_ORDER = gql`
mutation CancelOrder($orderId: Int, $cancellationReason: String) {
  cancelOrder(order_id: $orderId, cancellation_reason: $cancellationReason)
}
`;