import React from "react";
import Menu from "./Menu";
import UserContext from "../../Helpers/UserContext";
import Session from "../../Helpers/Session";
import { useQuery } from '@apollo/client';
import { GET_COURIER_ORDERS } from '../../GraphQL/Queries';
import NumberToMoney from '../../Helpers/numberToMoney';
import { DateTime } from 'luxon';
import { APIResponse } from "../../Helpers/General";

const Order = () => {
    const myContext = React.useContext(UserContext);
    const user = myContext.user || Session.get('user');
    const [active, setActive] = React.useState(1);
    const [orders, setOrders] = React.useState([]);
    const [offset, setOffset] = React.useState(0);

    const { refetch } = useQuery(GET_COURIER_ORDERS, {
        fetchPolicy: "network-only",
        variables: {
            offset: offset,
        },
        onCompleted: (data) => {
            setOrders(data.getCourierOrders);
        },
        onError: (error) => {
            APIResponse(error);
            Session.showAlert({ str: error.message, type: 'error' });
            return;
        }
    });

    const filterOrders = () => {
        if (active === 1) {
            return orders.filter((order: Record<string, any>) => order.status === 'Delivered');
        } else if (active === 2) {
            return orders.filter((order: Record<string, any>) => order.status === 'Cancelled');
        } else {
            return orders;
        }
    }

    return (
        <>
            <div className="w3-animate-left">
                <div className="bg-primary w-100">
                    <div className='d-flex align-items-baseline justify-content-between pt-4 pb-1'>
                        <div>
                            {/* <Link className="dark fs-3 ps-3" to="/wallet">
                                <i className="bi bi-arrow-left text-light fw-bolder"></i>
                            </Link> */}
                        </div>
                        <div className='w3-center fs-3 text-light d-flex justify-content-center'>
                            <h3 className='w3-center text-light mb-0'>Orders</h3>
                        </div>
                        <div className='me-3'></div>
                    </div>
                </div>
                <div className="tab-menu w-100 d-flex align-items-center">
                    <div className={"d-flex w-100 pt-4 pb-2 fw-bold justify-content-center " + (active === 1 ? "active-order-screen" : "")}
                        onClick={() => setActive(1)}
                    >
                        Completed
                    </div>
                    <div className={"d-flex w-100 pt-4 pb-2 fw-bold justify-content-center " + (active === 2 ? "active-order-screen" : "")}
                        onClick={() => setActive(2)}
                    >
                        Cancelled
                    </div>
                </div>
                <div className="container">
                    {
                        active === 1 && (
                            <div className="w3-animate-right">
                                {filterOrders().map((order: Record<string, any>) => (
                                    <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                                        <div className="py-2 d-flex align-items-center justify-content-between">
                                            <p className="mb-0 text-muted">{DateTime.fromSQL(order.created_at).toFormat("cccc, dd LLL, yyyy")}</p>
                                            <p className="mb-0 fw-bold text-success p-2 w3-round-xxlarge approved">Completed</p>
                                        </div>
                                        <div className="">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                            <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                        </svg>
                                                    </div>

                                                    <p className="mb-0 ps-2 text-muted">Pick Up: <span className="text-dark fw-bold">{order.pickup_address}</span></p>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center send-container me-3">
                                                    <p className="mb-0 text-muted text-nowrap">Delivery fee</p>
                                                </div>
                                            </div>
                                            {/* <i className="bi bi-three-dots-vertical text-muted p-0 m-0 fs-3"></i> */}
                                            {/* <div className="vertical-line"></div> */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                    <p className="mb-0 ps-2 text-muted">Drop Off: <span className="text-dark fw-bold">{order.receiver_address}</span></p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 fs-5 fw-bold">&#8358;&nbsp;{NumberToMoney(order.amount)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )
                    }{
                        active === 2 && (
                            <div className="w3-animate-left">
                                {filterOrders().map((order: Record<string, any>) => (
                                    <div className="shadow-none px-3 mb-2 py-3 bg-light w3-round-large">
                                        <div className="py-2 d-flex align-items-center justify-content-between">
                                            <p className="mb-0 text-muted">{DateTime.fromSQL(order.created_at).toFormat("cccc, dd LLL, yyyy")}</p>
                                            <p className="mb-0 fw-bold text-negative p-2 w3-round-xxlarge cancelled">Cancelled</p>
                                        </div>
                                        <div className="">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="21.75" height="21.75" fill="currentColor" className="bi bi-crosshair2 text-muted" viewBox="0 0 16 16">
                                                        <path d="M8 0a.5.5 0 0 1 .5.5v.518A7 7 0 0 1 14.982 7.5h.518a.5.5 0 0 1 0 1h-.518A7 7 0 0 1 8.5 14.982v.518a.5.5 0 0 1-1 0v-.518A7 7 0 0 1 1.018 8.5H.5a.5.5 0 0 1 0-1h.518A7 7 0 0 1 7.5 1.018V.5A.5.5 0 0 1 8 0m-.5 2.02A6 6 0 0 0 2.02 7.5h1.005A5 5 0 0 1 7.5 3.025zm1 1.005A5 5 0 0 1 12.975 7.5h1.005A6 6 0 0 0 8.5 2.02zM12.975 8.5A5 5 0 0 1 8.5 12.975v1.005a6 6 0 0 0 5.48-5.48zM7.5 12.975A5 5 0 0 1 3.025 8.5H2.02a6 6 0 0 0 5.48 5.48zM10 8a2 2 0 1 0-4 0 2 2 0 0 0 4 0" />
                                                    </svg>
                                                    <p className="mb-0 ps-2 text-muted">Pick Up: <span className="text-dark fw-bold">{order.pickup_address}</span></p>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center send-container me-3">
                                                    <p className="mb-0 text-muted text-nowrap">Delivery fee</p>
                                                </div>
                                            </div>
                                            {/* <i className="bi bi-three-dots-vertical text-muted p-0 m-0 fs-3"></i> */}
                                            {/* <div className="vertical-line"></div> */}
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="d-flex align-items-center">
                                                    <i className="bi bi-geo-alt-fill text-primary p-0 m-0 fs-3"></i>
                                                    <p className="mb-0 ps-2 text-muted">Drop Off: <span className="text-dark fw-bold">{order.receiver_address}</span></p>
                                                </div>
                                                <div>
                                                    <p className="mb-0 fs-5 fw-bold">&#8358;&nbsp;{NumberToMoney(order.amount)}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </div>
                        )
                    }
                </div>
                <Menu />
            </div>
        </>
    );
};

export default Order;